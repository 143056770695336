const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Один сервер, синхронизируйте все",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Пожертвовать для Amphi",
        apps: "Программы",
        games: "Игры",
        donate: "Пожертвовать",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Один сервер, синхронизируйте все",
        servers: "Серверы",
        downloadServer: "Скачать сервер",
        privacyPolicy: "Политика конфиденциальности",
        termsAndCondition: "Условия и положения",
        github: "Github",
        appStores: "Магазины приложений",
        binaries: "Бинарные файлы",
        packageManagers: "Менеджеры пакетов",
        allReleases: "Все выпуски",
        sourceCode: "Исходный код",
        notesAppName: "Заметки",
        notesAppSubtitle: "Минималистичный дизайн, максимальная функциональность",
        musicAppName: "Музыка",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Облако",
        photosAppName: "Фотографии",
        inPlanning: "В разработке",
        donationPageHeader: "Вам понравились наши приложения и игры?",
        donationMessage: "Ваша поддержка имеет для нас большое значение и помогает нам продолжать выпускать обновления и улучшать качество. Благодаря вашим пожертвованиям мы можем уделить больше времени разработке, что напрямую улучшает качество и полноту наших приложений и игр. Кроме того, ваши щедрые взносы помогают покрывать расходы на хостинг сайта и плату за регистрацию в магазинах приложений. Большое спасибо за вашу поддержку!",
        latestVersion: "Последняя"
    }
};

export default data;
