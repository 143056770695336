const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Ένας διακομιστής, συγχρονίστε τα όλα",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Δωρεά για το Amphi",
        apps: "Εφαρμογές",
        games: "Παιχνίδια",
        donate: "Δωρεά",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Ένας διακομιστής, συγχρονίστε τα όλα",
        appsPageSubtitle: "Με έναν μόνο διακομιστή, μπορείτε να συγχρονίσετε τα δεδομένα όλων των εφαρμογών παρακάτω.",
        servers: "Διακομιστές",
        downloadServer: "Λήψη διακομιστή",
        privacyPolicy: "Πολιτική Απορρήτου",
        termsAndCondition: "Όροι και Προϋποθέσεις",
        github: "Github",
        appStores: "Καταστήματα Εφαρμογών",
        binaries: "Δυαδικά αρχεία",
        packageManagers: "Διαχειριστές Πακέτων",
        allReleases: "Όλες οι εκδόσεις",
        sourceCode: "Κώδικας Πηγαίου Κώδικα",
        notesAppName: "Σημειώσεις",
        notesAppSubtitle: "Μινιμαλιστικός σχεδιασμός, μέγιστη λειτουργικότητα",
        musicAppName: "Μουσική",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Φωτογραφίες",
        inPlanning: "Σε Σχεδιασμό",
        donationPageHeader: "Απολαύσατε τις εφαρμογές και τα παιχνίδια μας;",
        donationMessage: "Η υποστήριξή σας σημαίνει πολλά για εμάς και μας βοηθά να συνεχίσουμε να παρέχουμε ενημερώσεις και να βελτιώνουμε την ποιότητα. Με τις δωρεές σας, μπορούμε να αφιερώσουμε περισσότερο χρόνο στην ανάπτυξη, κάτι που βελτιώνει άμεσα την ποιότητα και την ολοκλήρωση των εφαρμογών και των παιχνιδιών μας. Επιπλέον, οι γενναιόδωρες συνεισφορές σας βοηθούν στην κάλυψη των εξόδων φιλοξενίας ιστοσελίδων και των τελών εγγραφής στα καταστήματα εφαρμογών. Σας ευχαριστούμε πολύ για την υποστήριξή σας!",
        latestVersion: "Τελευταία"
    }
};

export default data;
