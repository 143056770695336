import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import PrivacyPolicyArchive from "./pages/archives/PrivacyPolicyArchive";
import Servers from "./pages/archives/Servers";
import Terms from "./pages/Terms";
import styled from "styled-components";
import TermsArchive from "./pages/archives/TermsArchive";
import NotesPage from "./pages/apps/NotesPage";
import NotFound from "./pages/NotFound";
import NotesAppArchive from "./pages/archives/NotesAppArchive";

function App() {

    const Container = styled.div`

        body {
            background-color: var(--background-color);
            color: var(--text-color);
            font-family: Arial, sans-serif;
        }
        
        background-color: var(--background-color);

        img {
            object-fit: contain;
        }

        button {
            border: none;
            outline: none;
            cursor: pointer;
        }
        
        //button:hover {
        //    background-color: #rgba(125, 125, 125, 0.2);
        //}

        a {
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    `;

    return (
        <Container>
            <Header/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/notes" element={<NotesPage/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/archive/notes" element={<NotesAppArchive/>}/>
                    <Route path="/archive/servers" element={<Servers/>}/>
                    <Route path="/archive/privacy" element={<PrivacyPolicyArchive/>}/>
                    <Route path="/archive/terms" element={<TermsArchive/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            <Footer/>
        </Container>
    );
}

export default App;
