import Page from "../components/Page";

function NotFound() {
    return (
        <Page>
            Not Found
        </Page>
    );
}

export default NotFound;