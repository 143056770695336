const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Jeden serwer, synchronizuj wszystko",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Przekaż darowiznę na rzecz Amphi",
        apps: "Aplikacje",
        games: "Gry",
        donate: "Darowizna",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Jeden serwer, synchronizuj wszystko",
        servers: "Serwery",
        downloadServer: "Pobierz serwer",
        privacyPolicy: "Polityka prywatności",
        termsAndCondition: "Regulamin",
        github: "Github",
        appStores: "Sklepy z aplikacjami",
        binaries: "Binarne pliki",
        packageManagers: "Menedżery pakietów",
        allReleases: "Wszystkie wydania",
        sourceCode: "Kod źródłowy",
        notesAppName: "Notatki",
        notesAppSubtitle: "Minimalistyczny design, maksymalna funkcjonalność",
        musicAppName: "Muzyka",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Chmura",
        photosAppName: "Zdjęcia",
        inPlanning: "W planach",
        donationPageHeader: "Czy podobały Ci się nasze aplikacje i gry?",
        donationMessage: "Twoje wsparcie wiele dla nas znaczy i pomaga nam kontynuować dostarczanie aktualizacji oraz poprawianie jakości. Dzięki Twoim darowiznom możemy poświęcić więcej czasu na rozwój, co bezpośrednio poprawia jakość i pełność naszych aplikacji i gier. Ponadto Twoje hojnie przekazane fundusze pomagają pokryć koszty hostingu strony internetowej i opłaty rejestracyjne w sklepach z aplikacjami. Dziękujemy za Twoje wsparcie!",
        latestVersion: "Najwyższa"
    }
};

export default data;
