const data = {
    translation: {
        appsPageTitle: "Amphi Apps - 一个服务器，同步一切",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "为 Amphi 捐款",
        apps: "应用程序",
        games: "游戏",
        donate: "捐款",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "一个服务器，同步一切",
        appsPageSubtitle: "一个服务器即可同步下面所有应用的数据。",
        servers: "服务器",
        downloadServer: "下载服务器",
        privacyPolicy: "隐私政策",
        termsAndCondition: "条款和条件",
        github: "Github",
        appStores: "应用商店",
        binaries: "二进制文件",
        packageManagers: "包管理器",
        allReleases: "所有版本",
        sourceCode: "源代码",
        notesAppName: "便签",
        notesAppSubtitle: "简约设计，最大功能",
        musicAppName: "音乐",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "云端",
        photosAppName: "照片",
        inPlanning: "计划中",
        donationPageHeader: "你喜欢我们的应用程序和游戏吗？",
        donationMessage: "您的支持对我们意义重大，帮助我们继续提供更新并改善质量。通过您的捐款，我们可以花更多时间在开发上，这将直接改善我们应用程序和游戏的质量和完整性。此外，您慷慨的捐赠帮助支付网站托管和应用商店注册费用。非常感谢您的支持！",
        latestVersion: "最新版本"
    }
};

export default data;
