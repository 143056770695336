const data = {
    translation: {
        appsPageTitle: "Amphi Apps - One Server, Sync All",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Donate for Amphi",
        apps: "Apps",
        games: "Games",
        donate: "Donate",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "One Server, Sync All",
        appsPageSubtitle: "एक ही सर्वर से, आप नीचे दिए गए सभी ऐप्स के डेटा को सिंक कर सकते हैं।",
        servers: "Servers",
        downloadServer: "Download Server",
        privacyPolicy: "Privacy",
        termsAndCondition: "Terms",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binaries",
        packageManagers: "Package Managers",
        allReleases: "All Releases",
        sourceCode: "Source Code",
        notesAppName: "Notes",
        notesAppSubtitle: "Minimal design, maximum functionality",
        musicAppName: "Music",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Photos",
        inPlanning: "In Planning",
        donationPageHeader: "Did you enjoy our app and games?",
        donationMessage: "Your support means the world to us and helps us continue providing updates and improving quality. With your donations, we can dedicate more time to development, which directly enhances the polish and completeness of our app and games. Additionally, your generous contributions help cover website hosting and app store registration fees. Thank you so much for your support!",
        latestVersion: "Latest",
    }
};

export default data;