const data = {
    translation: {
        appsPageTitle: "Amphi Apps - En server, synkronisera allt",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Donera till Amphi",
        apps: "Appar",
        games: "Spel",
        donate: "Donera",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "En server, synkronisera allt",
        servers: "Servrar",
        downloadServer: "Ladda ner server",
        privacyPolicy: "Sekretesspolicy",
        termsAndCondition: "Villkor",
        github: "Github",
        appStores: "Appbutiker",
        binaries: "Binärer",
        packageManagers: "Paketförvaltare",
        allReleases: "Alla versioner",
        sourceCode: "Källkod",
        notesAppName: "Anteckningar",
        notesAppSubtitle: "Minimal design, maximal funktionalitet",
        musicAppName: "Musik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Moln",
        photosAppName: "Foton",
        inPlanning: "Under planering",
        donationPageHeader: "Gillar du våra appar och spel?",
        donationMessage: "Ditt stöd betyder mycket för oss och hjälper oss att fortsätta ge uppdateringar och förbättra kvaliteten. Med dina donationer kan vi ägna mer tid åt utveckling, vilket direkt förbättrar kvaliteten och fullständigheten av våra appar och spel. Dessutom hjälper dina generösa bidrag till att täcka kostnader för webbhotell och appbutiksregistreringsavgifter. Tack så mycket för ditt stöd!",
        latestVersion: "Senaste"
    }
};

export default data;
