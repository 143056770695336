const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Tek bir sunucu, her şeyi senkronize et",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Amphi'ye Bağış Yap",
        apps: "Uygulamalar",
        games: "Oyunlar",
        donate: "Bağış Yap",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Tek bir sunucu, her şeyi senkronize et",
        servers: "Sunucular",
        downloadServer: "Sunucuyu indir",
        privacyPolicy: "Gizlilik Politikası",
        termsAndCondition: "Şartlar ve Koşullar",
        github: "Github",
        appStores: "Uygulama Mağazaları",
        binaries: "Binaryler",
        packageManagers: "Paket Yöneticileri",
        allReleases: "Tüm Sürümler",
        sourceCode: "Kaynak Kodu",
        notesAppName: "Notlar",
        notesAppSubtitle: "Minimalist tasarım, maksimum işlevsellik",
        musicAppName: "Müzik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Bulut",
        photosAppName: "Fotoğraflar",
        inPlanning: "Planlama Aşamasında",
        donationPageHeader: "Uygulamalarımızı ve oyunlarımızı beğendiniz mi?",
        donationMessage: "Destekleriniz bizim için çok şey ifade ediyor ve güncellemeler sunmamıza ve kaliteyi artırmamıza yardımcı oluyor. Bağışlarınızla, gelişmeye daha fazla zaman ayırabiliriz ve bu da doğrudan uygulamalarımızın ve oyunlarımızın kalitesini ve tamlığını iyileştirir. Ayrıca cömert bağışlarınız, web barındırma masraflarını ve uygulama mağazası kayıt ücretlerini karşılamamıza yardımcı olur. Destekleriniz için çok teşekkür ederiz!",
        latestVersion: "Son Sürüm"
    }
};

export default data;
