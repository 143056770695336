const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Một máy chủ, đồng bộ hóa tất cả",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Đóng góp cho Amphi",
        apps: "Ứng dụng",
        games: "Trò chơi",
        donate: "Đóng góp",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Một máy chủ, đồng bộ hóa tất cả",
        servers: "Máy chủ",
        downloadServer: "Tải về máy chủ",
        privacyPolicy: "Chính sách bảo mật",
        termsAndCondition: "Điều khoản và điều kiện",
        github: "Github",
        appStores: "Cửa hàng ứng dụng",
        binaries: "Tệp nhị phân",
        packageManagers: "Trình quản lý gói",
        allReleases: "Tất cả các phiên bản",
        sourceCode: "Mã nguồn",
        notesAppName: "Ghi chú",
        notesAppSubtitle: "Thiết kế tối giản, chức năng tối đa",
        musicAppName: "Nhạc",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Ảnh",
        inPlanning: "Đang lên kế hoạch",
        donationPageHeader: "Bạn có thích các ứng dụng và trò chơi của chúng tôi không?",
        donationMessage: "Sự hỗ trợ của bạn có ý nghĩa rất lớn đối với chúng tôi và giúp chúng tôi tiếp tục cung cấp các bản cập nhật và cải thiện chất lượng. Với các khoản đóng góp của bạn, chúng tôi có thể dành nhiều thời gian hơn cho việc phát triển, điều này sẽ cải thiện trực tiếp chất lượng và tính đầy đủ của các ứng dụng và trò chơi của chúng tôi. Ngoài ra, những đóng góp hào phóng của bạn giúp trang trải chi phí lưu trữ website và phí đăng ký cửa hàng ứng dụng. Cảm ơn bạn rất nhiều vì sự ủng hộ của bạn!",
        latestVersion: "Phiên bản mới nhất"
    }
};

export default data;
