const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Eén server, synchroniseer alles",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Doneer voor Amphi",
        apps: "Apps",
        games: "Spellen",
        donate: "Doneren",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Eén server, synchroniseer alles",
        servers: "Servers",
        downloadServer: "Download Server",
        privacyPolicy: "Privacybeleid",
        termsAndCondition: "Voorwaarden",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binaries",
        packageManagers: "Pakketbeheerders",
        allReleases: "Alle Releases",
        sourceCode: "Broncode",
        notesAppName: "Notities",
        notesAppSubtitle: "Minimalistisch ontwerp, maximale functionaliteit",
        musicAppName: "Muziek",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Foto's",
        inPlanning: "In planning",
        donationPageHeader: "Heb je genoten van onze apps en games?",
        donationMessage: "Je steun betekent veel voor ons en helpt ons om updates te blijven bieden en de kwaliteit te verbeteren. Met jouw donaties kunnen we meer tijd aan de ontwikkeling besteden, wat direct de kwaliteit en volledigheid van onze apps en games verbetert. Bovendien helpen je genereuze bijdragen de kosten voor website-hosting en app store registratiekosten te dekken. Heel erg bedankt voor je steun!",
        latestVersion: "Laatste"
    }
};

export default data;
