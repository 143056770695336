import {Command, DownloadButton} from "../../components/AppDownload";
import {useTranslation} from "react-i18next";
import Page from "../../components/Page";
import React from "react";

export default function Servers() {

    const [t] = useTranslation();

    return (
        <Page>

            <h1>Server for Apps</h1>

            <h2>1. Download JRE</h2>

            <p>
                Choose your favorite JRE
            </p>

            <DownloadButton href={"https://adoptium.net/temurin/releases/?package=jre"} target={"_blank"}
                            rel={"noreferrer"}>
                <i className="fa-regular fa-circle-down"></i>Adoptium
            </DownloadButton>
            <DownloadButton href={"https://www.azul.com/downloads/?package=jre"} target={"_blank"} rel={"noreferrer"}>
                <i className="fa-regular fa-circle-down"></i>Azul
            </DownloadButton>

            <h2>2. Download the Server</h2>

            <DownloadButton href={"/servers/latest"} target={"_blank"} rel={"noreferrer"}>
                <i className="fa-regular fa-circle-down"></i>{t("latestVersion")} (1.1.0)
            </DownloadButton>

            <p>
                If you want to download server in terminal, run this command
            </p>

            <Command>
                curl https://amphi.site/servers/latest
            </Command>
            or
            <Command>
                curl https://amphi.site/servers/server-1.1.0.jar
            </Command>

            <h2>3. Run the Server</h2>

            <h3>
                Open up the terminal, And run this command
            </h3>

            <Command>
                path/to/your/jre/bin/java -jar your-server.jar
            </Command>

            <h2>4. (Optional) Port Forward</h2>

            <p>
                Port forwarding is necessary if you want to make your server accessible over the internet.
                You’ll need to access your router’s settings page and forward the port used by your server to the
                internal IP of the machine running the server.
                Typically, servers use port 80 (HTTP) or 443 (HTTPS), but check your application’s documentation for the
                specific port.
                <br/>
                For more details, consult your router's manual or the following link to set up port forwarding:
                <a href="https://www.portforward.com/" target="_blank" rel="noopener noreferrer"> PortForward.com</a>
            </p>

            <h2>5. (Optional) Set Up HTTPS</h2>

            <p>
                If you want to use HTTPS, you will need to install and configure a reverse proxy such as Nginx, Tomcat,
                or Kestrel.
                A reverse proxy helps manage SSL certificates and route traffic securely to your server.
                <br/>
                Here’s an example of setting up Nginx in Ubuntu for SSL with Let’s Encrypt:
            </p>

            <Command>
                sudo apt-get install nginx
            </Command>
            <p>Installs the Nginx web server, which will handle the reverse proxy.</p>

            <Command>
                sudo apt-get install certbot python3-certbot-nginx
            </Command>
            <p>Installs Certbot, a tool to automatically obtain SSL certificates from Let's Encrypt.</p>

            <Command>
                sudo certbot --nginx -d yourdomain.com
            </Command>
            <p>Obtains and installs the SSL certificate for your domain using Let’s Encrypt.</p>

            <p>
                You will also need to set up an SSL certificate and link it to your domain name. Let’s Encrypt offers
                free SSL certificates, which are valid for 90 days.
                Alternatively, you can use Cloudflare for free SSL certificates and DNS management.
            </p>

            <h2>
                All Downloads
            </h2>

            <DownloadButton href={"/servers/server-1.1.0.jar"} target={"_blank"} rel={"noreferrer"}>
                <i className="fa-regular fa-circle-down"></i>1.1.0
            </DownloadButton>

            <DownloadButton href={"/servers/server-1.0.0.jar"} target={"_blank"} rel={"noreferrer"}>
                <i className="fa-regular fa-circle-down"></i>1.0.0
            </DownloadButton>
        </Page>
    );
}