const data = {
    translation: {
        appsPageTitle: "Amphi Apps - 1つのサーバーですべてを同期",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Amphiに寄付",
        apps: "アプリ",
        games: "ゲーム",
        donate: "寄付",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "1つのサーバーですべてを同期",
        appsPageSubtitle: "一つのサーバーで、下のすべてのアプリのデータを同期できます。",
        servers: "サーバー",
        downloadServer: "サーバーをダウンロード",
        privacyPolicy: "プライバシーポリシー",
        termsAndCondition: "利用規約",
        github: "Github",
        appStores: "アプリストア",
        binaries: "バイナリ",
        packageManagers: "パッケージマネージャー",
        allReleases: "すべてのリリース",
        sourceCode: "ソースコード",
        notesAppName: "ノート",
        notesAppSubtitle: "ミニマルデザイン、最大の機能",
        musicAppName: "音楽",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "クラウド",
        photosAppName: "写真",
        inPlanning: "計画中",
        donationPageHeader: "私たちのアプリとゲームを楽しんでいただけましたか？",
        donationMessage: "あなたのサポートは私たちにとって非常に重要で、更新を提供し、品質を向上させる手助けとなります。あなたの寄付によって、私たちは開発にもっと時間を割くことができ、それが直接的にアプリとゲームの品質と完成度を向上させます。また、あなたの寛大な寄付は、ウェブサイトのホスティング費用やアプリストアの登録費用をカバーする助けになります。ご支援いただきありがとうございます！",
        latestVersion: "最新"
    }
};

export default data;