const data = {
    translation: {
        appsPageTitle: "Amphi Apps - 하나의 서버로 모든것을 동기화",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Amphi에게 후원하기",
        apps: "앱",
        games: "게임",
        donate: "후원",
        windows: "윈도우",
        mac: "맥",
        linux: "리눅스",
        android: "안드로이드",
        ios: "IOS",
        appsPageHeader: "하나의 서버로 모든것을 동기화",
        appsPageSubtitle: "하나의 서버로, 아래에 있는 모든 앱의 데이터를 동기화할 수 있습니다.",
        servers: "서버",
        downloadServer: "서버 다운로드",
        privacyPolicy: "개인정보 처리방침",
        termsAndCondition: "이용 약관",
        github: "깃허브",
        appStores: "앱 스토어",
        binaries: "바이너리",
        packageManagers: "패키지 매니저",
        allReleases: "모든 릴리즈",
        sourceCode: "소스 코드",
        notesAppName: "노트",
        notesAppSubtitle: "가볍고 단순하지만 다재다능한 노트앱",
        musicAppName: "뮤직",
        cubeTimeAppName: "큐브타임",
        cloudAppName: "클라우드",
        photosAppName: "사진",
        inPlanning: "기획중",
        latestVersion: "최신"
    }
};

export default data;