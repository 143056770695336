const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Egy szerver, szinkronizáljon mindent",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Adományozz az Amphi-nak",
        apps: "Alkalmazások",
        games: "Játékok",
        donate: "Adományozás",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Egy szerver, szinkronizáljon mindent",
        servers: "Szerverek",
        downloadServer: "Letöltés szerver",
        privacyPolicy: "Adatvédelmi irányelvek",
        termsAndCondition: "Felhasználási feltételek",
        github: "Github",
        appStores: "Alkalmazásboltok",
        binaries: "Binaries",
        packageManagers: "Csomagkezelők",
        allReleases: "Minden kiadás",
        sourceCode: "Forráskód",
        notesAppName: "Jegyzetek",
        notesAppSubtitle: "Minimalista dizájn, maximális funkcionalitás",
        musicAppName: "Zene",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Felhő",
        photosAppName: "Fotók",
        inPlanning: "Tervezés alatt",
        donationPageHeader: "Élvezted a alkalmazásainkat és játékainkat?",
        donationMessage: "A támogatásod sokat jelent számunkra, és segít folytatnunk a frissítések kiadását és a minőség javítását. Az adományaiddal több időt tudunk szentelni a fejlesztésre, amely közvetlenül javítja alkalmazásaink és játékaink minőségét és teljességét. Ezen kívül a nagylelkű hozzájárulásaid segítenek fedezni a weboldal tárolási és alkalmazásbolt regisztrációs díjait. Nagyon köszönjük a támogatásodat!",
        latestVersion: "Legújabb"
    }
};

export default data;
