const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Ein Server, alles synchronisieren",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Spenden für Amphi",
        apps: "Apps",
        games: "Spiele",
        donate: "Spenden",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Ein Server, alles synchronisieren",
        appsPageSubtitle: "Mit einem einzigen Server können Sie die Daten aller unten aufgeführten Apps synchronisieren.",
        servers: "Server",
        downloadServer: "Server herunterladen",
        privacyPolicy: "Datenschutzrichtlinie",
        termsAndCondition: "Nutzungsbedingungen",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binärdateien",
        packageManagers: "Paketmanager",
        allReleases: "Alle Veröffentlichungen",
        sourceCode: "Quellcode",
        notesAppName: "Notizen",
        notesAppSubtitle: "Minimalistisches Design, maximale Funktionalität",
        musicAppName: "Musik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Fotos",
        inPlanning: "In Planung",
        donationPageHeader: "Haben Sie unsere Home und Spiele genossen?",
        donationMessage: "Ihre Unterstützung bedeutet uns viel und hilft uns, weiterhin Updates bereitzustellen und die Qualität zu verbessern. Mit Ihren Spenden können wir mehr Zeit für die Entwicklung aufwenden, was direkt die Qualität und Vollständigkeit unserer Home und Spiele verbessert. Darüber hinaus helfen Ihre großzügigen Beiträge, die Kosten für die Webhosting und die Registrierungsgebühren der App-Stores zu decken. Vielen Dank für Ihre Unterstützung!",
        latestVersion: "Neueste"
    }
};

export default data;