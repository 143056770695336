const data = {
    translation: {
        appsPageTitle: "Amphi Apps - En server, synkroniser alt",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Doner til Amphi",
        apps: "Apper",
        games: "Spill",
        donate: "Doner",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "En server, synkroniser alt",
        servers: "Servere",
        downloadServer: "Last ned server",
        privacyPolicy: "Personvern",
        termsAndCondition: "Vilkår og betingelser",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binærer",
        packageManagers: "Pakkebehandlere",
        allReleases: "Alle utgivelser",
        sourceCode: "Kildekode",
        notesAppName: "Notater",
        notesAppSubtitle: "Minimalistisk design, maksimal funksjonalitet",
        musicAppName: "Musikk",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Bilder",
        inPlanning: "Under planlegging",
        donationPageHeader: "Likte du appene og spillene våre?",
        donationMessage: "Støtten din betyr mye for oss og hjelper oss med å fortsette å gi oppdateringer og forbedre kvaliteten. Med donasjonene dine kan vi bruke mer tid på utviklingen, noe som direkte forbedrer kvaliteten og fullstendigheten til appene og spillene våre. I tillegg hjelper de generøse bidragene dine med å dekke kostnader for webhotell og app store-registreringsavgifter. Tusen takk for din støtte!",
        latestVersion: "Nyeste"
    }
};

export default data;
