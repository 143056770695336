const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Один сервер, синхронізуйте все",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Пожертвувати для Amphi",
        apps: "Додатки",
        games: "Ігри",
        donate: "Пожертвувати",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Один сервер, синхронізуйте все",
        servers: "Сервери",
        downloadServer: "Завантажити сервер",
        privacyPolicy: "Політика конфіденційності",
        termsAndCondition: "Умови та положення",
        github: "Github",
        appStores: "Магазини додатків",
        binaries: "Бінарні файли",
        packageManagers: "Менеджери пакетів",
        allReleases: "Усі версії",
        sourceCode: "Код джерела",
        notesAppName: "Нотатки",
        notesAppSubtitle: "Мінімалістичний дизайн, максимальна функціональність",
        musicAppName: "Музика",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Хмара",
        photosAppName: "Фотографії",
        inPlanning: "У планах",
        donationPageHeader: "Вам сподобались наші додатки та ігри?",
        donationMessage: "Ваша підтримка дуже важлива для нас і допомагає нам продовжувати надавати оновлення та покращувати якість. З вашими пожертвами ми можемо присвятити більше часу розвитку, що безпосередньо покращує якість і завершеність наших додатків та ігор. Крім того, ваші щедрі внески допомагають покрити витрати на хостинг сайту та збори за реєстрацію в магазинах додатків. Велике спасибі за вашу підтримку!",
        latestVersion: "Остання"
    }
};

export default data;
