const data = {
    translation: {
        appsPageTitle: "Amphi Apps - ਇੱਕ ਸਰਵਰ, ਸਾਰੇ ਸਿੰਕ ਕਰੋ",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Amphi ਲਈ ਦਾਨ ਕਰੋ",
        apps: "ਐਪਸ",
        games: "ਖੇਡਾਂ",
        donate: "ਦਾਨ ਕਰੋ",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "ਇੱਕ ਸਰਵਰ, ਸਾਰੇ ਸਿੰਕ ਕਰੋ",
        servers: "ਸਰਵਰ",
        downloadServer: "ਸਰਵਰ ਡਾਊਨਲੋਡ ਕਰੋ",
        privacyPolicy: "ਪ੍ਰਾਈਵੇਸੀ ਨੀਤੀ",
        termsAndCondition: "ਸ਼ਰਤਾਂ ਅਤੇ ਸ਼ਰਤਾਂ",
        github: "Github",
        appStores: "ਐਪ ਸਟੋਰ",
        binaries: "ਬਾਈਨਰੀ",
        packageManagers: "ਪੈਕੇਜ ਮੈਨੇਜਰ",
        allReleases: "ਸਾਰੇ ਰੀਲੀਜ਼",
        sourceCode: "ਸੋਰਸ ਕੋਡ",
        notesAppName: "ਨੋਟਸ",
        notesAppSubtitle: "ਮੀਨਿਮਲ ਡਿਜ਼ਾਈਨ, ਅਧਿਕਤਮ ਫੰਕਸ਼ਨਲਿਟੀ",
        musicAppName: "ਸੰਗੀਤ",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "ਕਲਾਉਡ",
        photosAppName: "ਫੋਟੋਆਂ",
        inPlanning: "ਯੋਜਨਾ ਵਿੱਚ",
        donationPageHeader: "ਕੀ ਤੁਹਾਨੂੰ ਸਾਡੇ ਐਪਸ ਅਤੇ ਖੇਡਾਂ ਪਸੰਦ ਆਈਆਂ?",
        donationMessage: "ਤੁਹਾਡੀ ਸਹਾਇਤਾ ਸਾਡੇ ਲਈ ਬਹੁਤ ਅਹਮ ਹੈ ਅਤੇ ਸਾਡੇ ਲਈ ਅਪਡੇਟ ਦਿੰਦੇ ਰਹਿਣ ਅਤੇ ਗੁਣਵੱਤਾ ਵਿੱਚ ਸੁਧਾਰ ਕਰਨ ਵਿੱਚ ਮਦਦ ਕਰਦੀ ਹੈ। ਤੁਹਾਡੇ ਦਾਨ ਨਾਲ ਅਸੀਂ ਵਿਕਾਸ ਲਈ ਵਧੇਰੇ ਸਮੇਂ ਦੀ ਸਮਰਪਿਤ ਕਰ ਸਕਦੇ ਹਾਂ, ਜਿਸ ਨਾਲ ਸਾਡੇ ਐਪਸ ਅਤੇ ਖੇਡਾਂ ਦੀ ਗੁਣਵੱਤਾ ਅਤੇ ਪੂਰਨਤਾ ਨੂੰ ਸਿੱਧਾ ਬਿਹਤਰ ਬਣਾਇਆ ਜਾ ਸਕਦਾ ਹੈ। ਇਸਦੇ ਨਾਲ ਨਾਲ, ਤੁਹਾਡੀ ਦਾਨੀ ਯੋਗਦਾਨ ਸਾਡੇ ਵੈਬਸਾਈਟ ਹੋਸਟਿੰਗ ਅਤੇ ਐਪ ਸਟੋਰ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਫੀਸਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਵਿੱਚ ਮਦਦ ਕਰਦੀ ਹੈ। ਤੁਹਾਡੀ ਸਹਾਇਤਾ ਲਈ ਬਹੁਤ ਧੰਨਵਾਦ!",
        latestVersion: "ਤਾਜ਼ਾ"
    }
};

export default data;
