const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Satu Pelayan, Sinkronkan Semua",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Derma untuk Amphi",
        apps: "Aplikasi",
        games: "Permainan",
        donate: "Derma",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Satu Pelayan, Sinkronkan Semua",
        servers: "Pelayan",
        downloadServer: "Muat turun Pelayan",
        privacyPolicy: "Polisi Privasi",
        termsAndCondition: "Terma dan Syarat",
        github: "Github",
        appStores: "Kedai Aplikasi",
        binaries: "Binari",
        packageManagers: "Pengurus Pakej",
        allReleases: "Semua Keluaran",
        sourceCode: "Kod Sumber",
        notesAppName: "Nota",
        notesAppSubtitle: "Reka bentuk minimalis, fungsian maksimum",
        musicAppName: "Muzik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Awan",
        photosAppName: "Gambar",
        inPlanning: "Dalam Perancangan",
        donationPageHeader: "Adakah anda menikmati aplikasi dan permainan kami?",
        donationMessage: "Sokongan anda sangat bermakna bagi kami dan membantu kami untuk terus menyediakan kemas kini serta meningkatkan kualiti. Dengan derma anda, kami dapat meluangkan lebih banyak masa untuk pembangunan yang terus meningkatkan kualiti dan kesempurnaan aplikasi serta permainan kami. Selain itu, sumbangan ikhlas anda membantu untuk menampung kos hosting laman web dan yuran pendaftaran kedai aplikasi. Terima kasih banyak atas sokongan anda!",
        latestVersion: "Terkini"
    }
};

export default data;
