import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import styled from "styled-components";
import {useRef} from "react";

function ViewPager({children}) {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false
    };

    const CustomViewPager = styled.div`
        width: 90%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    `;

    const ArrowButton = styled.button`
        font-size: 2rem !important;
    `;

    return (
        <CustomViewPager>
            <ArrowButton onClick={() => {
                sliderRef.current.slickPrev();
            }}>
                <i className="fa-solid fa-circle-chevron-left"></i>
            </ArrowButton>
            <Slider {...settings} ref={sliderRef} style={{
                width: '80%',
            }}>
                {children}
            </Slider>
            <ArrowButton onClick={() => {
                sliderRef.current.slickNext();
            }}>
                <i className="fa-solid fa-circle-chevron-right"></i>
            </ArrowButton>
        </CustomViewPager>

    );
}

export default ViewPager;