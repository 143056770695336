import notesAppLogo from "../../assets/notes-logo.png";
import notesAppPreview from "../../assets/notes-preview.png";
import {Section, SectionTexts, Logo, AppPreview} from "../Home";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {DownloadButton, DownloadButtonGroup, SelectOs} from "../../components/AppDownload";

export default function NotesPage({osIndex}) {

    const [t] = useTranslation();
    const [index, setIndex] = useState(osIndex);

    const fragments = [
            (
                <DownloadButtonGroup>
                    <DownloadButton href={"/releases/notes/notes-windows-x64-1.1.0.exe"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>EXE
                    </DownloadButton>
                    <DownloadButton href={"/releases/notes/notes-windows-x64-1.1.0.zip"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>ZIP
                    </DownloadButton>

                </DownloadButtonGroup>
            ),
            (
                <DownloadButtonGroup>
                    <DownloadButton href={"/releases/notes/notes-macos-1.1.0.dmg"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>DMG
                    </DownloadButton>
                </DownloadButtonGroup>
            ),
            (

                <DownloadButtonGroup>

                    <DownloadButton  href={"/releases/notes/notes-linux-x64-1.1.0.tar.zst"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>TAR
                    </DownloadButton>

                    <DownloadButton href={"/releases/notes/notes-linux-x64-1.1.0.deb"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>DEB
                    </DownloadButton>

                    <DownloadButton href={"/releases/notes/notes-linux-x64-1.1.0.rpm"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>RPM
                    </DownloadButton>

                    <DownloadButton href={"/releases/notes/notes-linux-arm64-1.1.0.tar.zst"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>TAR (ARM)
                    </DownloadButton>
                    <DownloadButton href={"/releases/notes/notes-linux-arm64-1.1.0.deb"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>DEB (ARM)
                    </DownloadButton>
                    <DownloadButton href={"https://aur.archlinux.org/packages/amphi-notes"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>AUR
                    </DownloadButton>

                </DownloadButtonGroup>
            ),
            (
                <DownloadButtonGroup>

                    <DownloadButton href={"/releases/notes/notes-android-1.1.0.apk"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>APK
                    </DownloadButton>
                    <DownloadButton href={"https://play.google.com/store/apps/details?id=com.amphi.notes"} target={"_blank"} rel="noreferrer">
                        <i className="fa-brands fa-google"></i>Google Play
                    </DownloadButton>

                    <DownloadButton href={"https://m.onestore.co.kr/ko-kr/apps/appsDetail.omp?prodId=0000780627"} target={"_blank"} rel="noreferrer">
                        <i className="fa-regular fa-circle-down"></i>One Store
                    </DownloadButton>
                </DownloadButtonGroup>
            ),
            (
                <DownloadButtonGroup>
                    <DownloadButton href={"https://apps.apple.com/app/amphi-notes/id6740662483"} target={"_blank"} rel="noreferrer">
                        <i className="fa-brands fa-apple"></i>App Store
                    </DownloadButton>
                </DownloadButtonGroup>
            )
        ]
    ;

    return (

        <Section>

            <AppPreview src={notesAppPreview} alt={"notesAppPreview"}/>

            <SectionTexts>


                <h2>
                    <Logo src={notesAppLogo} alt={"notesAppLogo"}/>{t("notesAppName")}
                </h2>

                <SelectOs>
                    <button onClick={() => setIndex(0)}>
                        <i className="fa-brands fa-windows"
                           style={{color: index === 0 ? "var(--highlight-color)" : null}}></i>
                    </button>
                    <button onClick={() => setIndex(1)}>
                        <i className="fa-brands fa-apple"
                           style={{color: index === 1 ? "var(--highlight-color)" : null}}></i>
                    </button>
                    <button onClick={() => setIndex(2)}>
                        <i className="fa-brands fa-linux"
                           style={{color: index === 2 ? "var(--highlight-color)" : null}}></i>
                    </button>
                    <button onClick={() => setIndex(3)}>
                        <i className="fa-brands fa-android"
                           style={{color: index === 3 ? "var(--highlight-color)" : null}}></i>
                    </button>
                    <button onClick={() => setIndex(4)}>
                        <i className="fa-brands fa-apple"
                           style={{color: index === 4 ? "var(--highlight-color)" : null}}></i>
                    </button>
                </SelectOs>

                {fragments[index]}

                <DownloadButton href={"/archive/notes"} target={"_blank"} rel="noreferrer">
                    <i className="fas fa-history"></i>
                    <span>
                    {t("allReleases")}
                </span>
                </DownloadButton>

            </SectionTexts>

        </Section>
    )
        ;
}