const data = {
    translation: {
        appsPageTitle: "Amphi Apps - ایک سرور، سب کچھ ہم آہنگ کریں",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Amphi کے لیے عطیہ کریں",
        apps: "ایپس",
        games: "گیمز",
        donate: "عطیہ کریں",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "ایک سرور، سب کچھ ہم آہنگ کریں",
        servers: "سرورز",
        downloadServer: "سرور ڈاؤن لوڈ کریں",
        privacyPolicy: "پرائیویسی پالیسی",
        termsAndCondition: "شرائط و ضوابط",
        github: "Github",
        appStores: "ایپ اسٹورز",
        binaries: "بائنری فائلز",
        packageManagers: "پیکج مینیجرز",
        allReleases: "تمام ورژنز",
        sourceCode: "سورس کوڈ",
        notesAppName: "نوٹس",
        notesAppSubtitle: "سادہ ڈیزائن، زیادہ سے زیادہ فعالیت",
        musicAppName: "موسیقی",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "کلاؤڈ",
        photosAppName: "تصاویر",
        inPlanning: "منصوبہ بندی میں",
        donationPageHeader: "کیا آپ کو ہمارے ایپس اور گیمز پسند آئیں؟",
        donationMessage: "آپ کی حمایت ہمارے لیے بہت معنی رکھتی ہے اور ہمیں اپڈیٹس فراہم کرنے اور معیار کو بہتر بنانے میں مدد دیتی ہے۔ آپ کے عطیات سے ہم ترقی پر زیادہ وقت صرف کر سکتے ہیں، جو ہماری ایپس اور گیمز کے معیار اور تکمیل کو براہ راست بہتر کرتا ہے۔ مزید یہ کہ آپ کی فراخدلی سے دی گئی عطیات ویب ہوسٹنگ اور ایپ اسٹور کی رجسٹریشن فیس کو پورا کرنے میں مدد کرتی ہیں۔ آپ کی حمایت کا بہت شکریہ!",
        latestVersion: "آخری"
    }
};

export default data;
