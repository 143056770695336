import styled from "styled-components";
import {Link} from "react-router-dom";
import googlePlayStoreBadge from "../assets/google-play-badge.png";
import msStoreBadge from "../assets/ms-store-badge.png";
import appleAppStoreBadge from "../assets/apple-app-store-badge.png";
import oneStoreBadge from "../assets/onestore-badge.svg";
import {useTranslation} from "react-i18next";
import {useState} from "react";

export function Command({children}) {

    const [icon, setIcon] = useState(<i className="fa-solid fa-dollar-sign"></i>);
    const DollarSign = styled.span`
        color: rgb(123, 123, 123);
    `;

    const Code = styled.code`
        background-color: var(--footer-background-color);
        color: var(--text-color);
        display: flex;
        gap: 10px;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: rgba(125, 125, 125, 0.2);
        }
    `;

    return (


        <Code onClick={() => {
            setIcon(<i className="fa-solid fa-check"></i>);
            navigator.clipboard.writeText(children);
            setInterval(() => {
                setIcon(<i className="fa-solid fa-dollar-sign"></i>);
            }, 2000);
        }}>
            <DollarSign>{icon}</DollarSign>
            <span>
                    {children}
                </span>
        </Code>


    );
}

export const SelectOs = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;

    button {
        background: transparent;
        font-size: 2.5rem !important;
    }

    button:hover {
        background: transparent;
    }

    i {
        color: var(--disabled-color);
    }
`;

export const DownloadButton = styled.a`
    color: var(--highlight-color);
    font-size: 1rem;
    border-radius: 10px;
    font-weight: bold;
    padding: 3px;
    cursor: pointer;

    i {
        margin-right: 8px;
    }
`;

export const DownloadButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;