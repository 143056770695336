const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Un server, sincronizează totul",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Donează pentru Amphi",
        apps: "Aplicații",
        games: "Jocuri",
        donate: "Donează",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Un server, sincronizează totul",
        servers: "Servere",
        downloadServer: "Descarcă server",
        privacyPolicy: "Politica de confidențialitate",
        termsAndCondition: "Termeni și condiții",
        github: "Github",
        appStores: "Magazine de aplicații",
        binaries: "Binaries",
        packageManagers: "Manageri de pachete",
        allReleases: "Toate versiunile",
        sourceCode: "Cod sursă",
        notesAppName: "Note",
        notesAppSubtitle: "Design minimalist, funcționalitate maximă",
        musicAppName: "Muzică",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Fotografii",
        inPlanning: "În planificare",
        donationPageHeader: "Ți-au plăcut aplicațiile și jocurile noastre?",
        donationMessage: "Suportul tău înseamnă mult pentru noi și ne ajută să continuăm să oferim actualizări și să îmbunătățim calitatea. Cu donațiile tale, putem dedica mai mult timp dezvoltării, ceea ce îmbunătățește direct calitatea și completitudinea aplicațiilor și jocurilor noastre. În plus, contribuțiile tale generoase ne ajută să acoperim costurile de găzduire a site-ului și taxele de înregistrare a magazinelor de aplicații. Îți mulțumim mult pentru sprijinul tău!",
        latestVersion: "Ultima"
    }
};

export default data;
