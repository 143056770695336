const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Én server, synkroniser alt",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Doner til Amphi",
        apps: "Apps",
        games: "Spil",
        donate: "Doner",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Én server, synkroniser alt",
        servers: "Servere",
        downloadServer: "Download server",
        privacyPolicy: "Privatlivspolitik",
        termsAndCondition: "Betingelser",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binære filer",
        packageManagers: "Pakkestyring",
        allReleases: "Alle udgivelser",
        sourceCode: "Kildekode",
        notesAppName: "Noter",
        notesAppSubtitle: "Minimalistisk design, maksimal funktionalitet",
        musicAppName: "Musik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Fotos",
        inPlanning: "Under planlægning",
        donationPageHeader: "Har du nydt vores apps og spil?",
        donationMessage: "Din støtte betyder meget for os og hjælper os med at fortsætte med at levere opdateringer og forbedre kvaliteten. Med dine donationer kan vi afsætte mere tid til udvikling, hvilket direkte forbedrer vores apps og spil. Desuden hjælper dine generøse bidrag med at dække webstedshosting og app store-registreringsgebyrer. Mange tak for din støtte!",
        latestVersion: "Nyeste"
    }
};

export default data;