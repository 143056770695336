const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Un servidor, sincroniza todo",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Dona para Amphi",
        apps: "Aplicaciones",
        games: "Juegos",
        donate: "Donar",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Un servidor, sincroniza todo",
        appsPageSubtitle: "Con un solo servidor, puedes sincronizar los datos de todas las aplicaciones que se encuentran abajo.",
        servers: "Servidores",
        downloadServer: "Descargar servidor",
        privacyPolicy: "Política de privacidad",
        termsAndCondition: "Términos y condiciones",
        github: "Github",
        appStores: "Tiendas de aplicaciones",
        binaries: "Binarios",
        packageManagers: "Gestores de paquetes",
        allReleases: "Todas las versiones",
        sourceCode: "Código fuente",
        notesAppName: "Notas",
        notesAppSubtitle: "Diseño minimalista, máxima funcionalidad",
        musicAppName: "Música",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Nube",
        photosAppName: "Fotos",
        inPlanning: "En planificación",
        donationPageHeader: "¿Te han gustado nuestras aplicaciones y juegos?",
        donationMessage: "¡Tu apoyo significa mucho para nosotros y nos ayuda a seguir ofreciendo actualizaciones y mejorando la calidad! Con tus donaciones, podemos dedicar más tiempo al desarrollo, lo que mejora directamente la calidad y la completitud de nuestras aplicaciones y juegos. Además, tus generosas contribuciones nos ayudan a cubrir los costos de alojamiento del sitio web y las tarifas de registro en las tiendas de aplicaciones. ¡Muchas gracias por tu apoyo!",
        latestVersion: "Última"
    }
};

export default data;
