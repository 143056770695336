const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Un server, sincronizza tutto",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Dona per Amphi",
        apps: "App",
        games: "Giochi",
        donate: "Dona",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Un server, sincronizza tutto",
        servers: "Server",
        downloadServer: "Scarica server",
        privacyPolicy: "Privacy",
        termsAndCondition: "Termini e condizioni",
        github: "Github",
        appStores: "Negozio di app",
        binaries: "Binari",
        packageManagers: "Gestori di pacchetti",
        allReleases: "Tutte le versioni",
        sourceCode: "Codice sorgente",
        notesAppName: "Note",
        notesAppSubtitle: "Design minimalista, massima funzionalità",
        musicAppName: "Musica",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Foto",
        inPlanning: "In pianificazione",
        donationPageHeader: "Ti sono piaciute le nostre app e giochi?",
        donationMessage: "Il tuo supporto significa molto per noi e ci aiuta a continuare a fornire aggiornamenti e migliorare la qualità. Con le tue donazioni, possiamo dedicare più tempo allo sviluppo, il che migliora direttamente la qualità e la completezza delle nostre app e giochi. Inoltre, le tue generose contribuzioni ci aiutano a coprire le spese di hosting del sito web e le tariffe di registrazione degli store di app. Grazie mille per il tuo supporto!",
        latestVersion: "Ultima"
    }
};

export default data;
