const data = {
    translation: {
        appsPageTitle: "تطبيقات أمفي - خادم واحد، مزامنة الجميع",
        gamesPageTitle: "ألعاب أمفي",
        donatePageTitle: "تبرع لأمفي",
        apps: "التطبيقات",
        games: "الألعاب",
        donate: "تبرع",
        windows: "ويندوز",
        mac: "ماك",
        linux: "لينكس",
        android: "أندرويد",
        ios: "IOS",
        appsPageHeader: "خادم واحد، مزامنة الجميع",
        appsPageSubtitle: "باستخدام خادم واحد، يمكنك مزامنة بيانات جميع التطبيقات الموجودة أدناه.",
        servers: "الخوادم",
        downloadServer: "تنزيل الخادم",
        privacyPolicy: "الخصوصية",
        termsAndCondition: "الشروط والأحكام",
        github: "جيتهب",
        appStores: "متاجر التطبيقات",
        binaries: "الملفات الثنائية",
        packageManagers: "مديري الحزم",
        allReleases: "جميع الإصدارات",
        sourceCode: "رمز المصدر",
        notesAppName: "الملاحظات",
        notesAppSubtitle: "تصميم بسيط، أقصى قدر من الوظائف",
        musicAppName: "الموسيقى",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "السحابة",
        photosAppName: "الصور",
        inPlanning: "في التخطيط",
        donationPageHeader: "هل استمتعت بتطبيقاتنا وألعابنا؟",
        donationMessage: "دعمك يعني الكثير بالنسبة لنا ويساعدنا في مواصلة تقديم التحديثات وتحسين الجودة. مع تبرعاتك، يمكننا تخصيص المزيد من الوقت للتطوير، مما يعزز بشكل مباشر من جودة وكمال تطبيقاتنا وألعابنا. بالإضافة إلى ذلك، تساعد تبرعاتك السخية في تغطية رسوم استضافة المواقع ورسوم التسجيل في متاجر التطبيقات. شكراً جزيلاً لدعمك!",
        latestVersion: "أحدث"
    }
};

export default data;
