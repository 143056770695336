const data = {
    translation: {
        appsPageTitle: "Amphi Apps - שרת אחד, סנכרן הכל",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "תרום לאמפי",
        apps: "אפליקציות",
        games: "משחקים",
        donate: "תרום",
        windows: "ווינדוס",
        mac: "מַק",
        linux: "לינוקס",
        android: "אנדרואיד",
        ios: "IOS",
        appsPageHeader: "שרת אחד, סנכרן הכל",
        servers: "שרתים",
        downloadServer: "הורד שרת",
        privacyPolicy: "מדיניות פרטיות",
        termsAndCondition: "תנאים והגבלות",
        github: "גיטהאב",
        appStores: "חנויות אפליקציות",
        binaries: "קבצים בינאריים",
        packageManagers: "מנהלי חבילות",
        allReleases: "כל הגרסאות",
        sourceCode: "קוד מקור",
        notesAppName: "הערות",
        notesAppSubtitle: "עיצוב מינימליסטי, פונקציונליות מקסימלית",
        musicAppName: "מוזיקה",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "ענן",
        photosAppName: "תמונות",
        inPlanning: "בתכנון",
        donationPageHeader: "האם נהניתם מהאפליקציות והמשחקים שלנו?",
        donationMessage: "הסיוע שלך חשוב לנו מאוד ועוזר לנו להמשיך לספק עדכונים ולשפר את האיכות. עם תרומותיך, אנו יכולים להקדיש יותר זמן לפיתוח, מה שמשפר ישירות את איכות ושלמות האפליקציות והמשחקים שלנו. בנוסף, תרומותיך הנדיבות עוזרות לכסות את עלויות אחסון האתר ודמי רישום בחנויות האפליקציות. תודה רבה על תמיכתך!",
        latestVersion: "הגרסה האחרונה"
    }
};

export default data;
