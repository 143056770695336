const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Un serveur, synchronisez tout",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Faire un don pour Amphi",
        apps: "Applications",
        games: "Jeux",
        donate: "Faire un don",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Un serveur, synchronisez tout",
        servers: "Serveurs",
        downloadServer: "Télécharger le serveur",
        privacyPolicy: "Politique de confidentialité",
        termsAndCondition: "Conditions d'utilisation",
        github: "Github",
        appStores: "Magasins d'applications",
        binaries: "Fichiers binaires",
        packageManagers: "Gestionnaires de paquets",
        allReleases: "Toutes les versions",
        sourceCode: "Code source",
        notesAppName: "Notes",
        notesAppSubtitle: "Design minimaliste, fonctionnalité maximale",
        musicAppName: "Musique",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Photos",
        inPlanning: "En planification",
        donationPageHeader: "Avez-vous apprécié nos applications et jeux ?",
        donationMessage: "Votre soutien signifie beaucoup pour nous et nous aide à continuer de fournir des mises à jour et à améliorer la qualité. Avec vos dons, nous pouvons consacrer plus de temps au développement, ce qui améliore directement la qualité et l'intégralité de nos applications et jeux. De plus, vos généreuses contributions aident à couvrir les frais d'hébergement du site web et les frais d'inscription dans les magasins d'applications. Merci beaucoup pour votre soutien !",
        latestVersion: "Dernière version"
    }
};

export default data;
