import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

function Footer() {

    const {t} = useTranslation();

    const AppFooter = styled.footer`
        text-align: center;
        padding: 20px;
        background-color: var(--footer-background-color);

        * {
            margin: var(--item-margin);
        }

        a {
            font-size: 15px;
            color: var(--text-color);
            font-weight: bold;
        }
    `;

    return (
        <AppFooter className="AppFooter">
            <Link to={"/privacy"}>
                {t("privacyPolicy")}
            </Link>
            <Link to={"/terms"}>
                {t("termsAndCondition")}
            </Link>
            <Link to={"https://github.com/amphi2024"}>
                {t("github")}
            </Link>
        </AppFooter>
    );
}

export default Footer;