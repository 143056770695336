import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";
import NotesPage from "./apps/NotesPage";
import {useTranslation} from "react-i18next";
import Page from "../components/Page";
import React, {useEffect, useState} from "react";
import Popup from "../components/Popup";
import kofiButton from "../assets/support_me_on_kofi_blue.png";
import bmcButton from "../assets/bmc-button.png";
import ViewPager from "../components/ViewPager";
import MusicPage from "./apps/MusicPage";
import CloudPage from "./apps/CloudPage";
import PhotosPage from "./apps/PhotosPage";

const Section = styled.section`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--item-margin);
        gap: var(--item-margin);
        flex-direction: row;
        flex-flow: wrap;
    //width: 100%;
    
    h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    //@media (min-width: 200px) {
    //    flex-direction: column;
    //    //width: 80%;
    //}
    
    `;

const SectionTexts = styled.div`
        display: flex;
        flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    `;

const Logo = styled.img`
    width: 4rem;
    margin-right: 1rem;
    `;
export const AppPreview = styled.img`
        width: 100%;
    max-width: 900px;
        object-fit: contain;
    `;
const DonateImageButton = styled.img`
        height: 60px;
        margin: 8px;
        cursor: pointer;
    `;

function Home() {

    const [t] = useTranslation();
    const [isPopupOpened, setPopupOpen] = useState(false);
    const [osIndex, setOsIndex] = useState(0);
    const location = useLocation();
    useEffect(() => {
        document.title = t('appsPageTitle');
        const userAgent = navigator.userAgent;

        // Check for different OS patterns in userAgent
        if (/Windows NT/i.test(userAgent)) {
            setOsIndex(0);
        } else if (/Mac OS X/i.test(userAgent) && !/iPhone|iPad|iPod/i.test(userAgent)) {
            setOsIndex(1);
        } else if (/Linux/i.test(userAgent) && !/Android/i.test(userAgent)) {
            setOsIndex(2);
        } else if (/Android/i.test(userAgent)) {
            setOsIndex(3);
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            setOsIndex(4);
        } else {
            setOsIndex(0);
        }
    }, [location, t]);

    return (
        <Page>

            <h1>
                {t("appsPageHeader")}
            </h1>

            {/*<Link to="/archive/servers">*/}
            {/*    <button>{t("downloadServer")}</button>*/}
            {/*</Link>*/}
            <p>
                {t("appsPageSubtitle")}
            </p>

            <Link to="/archive/servers">
                <button>Set up a Server</button>
            </Link>

            <ViewPager>

                <NotesPage osIndex={osIndex}/>
                <MusicPage osIndex={osIndex}/>
                <PhotosPage osIndex={osIndex}/>
                <CloudPage osIndex={osIndex}/>
            </ViewPager>



            <Popup isOpen={isPopupOpened} onClose={() => setPopupOpen(false)} width={"50rem"}>

                <h1>
                    {t("donationPageHeader")}
                </h1>
                <p>
                    {t("donationMessage")}
                </p>

                <a href={"https://ko-fi.com/amphi2024"} target={"_blank"} rel="noopener noreferrer">
                    <DonateImageButton src={kofiButton} alt={"ko-fi"}/>
                </a>

                <a href={"https://buymeacoffee.com/amphiprionn"} target={"_blank"} rel="noopener noreferrer">
                    <DonateImageButton src={bmcButton} alt={"ko-fi"}/>
                </a>

            </Popup>

        </Page>
    );
}

export default Home;
export {
    Section,
    SectionTexts,
    Logo
};