const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Yksi palvelin, synkronoi kaikki",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Lahjoita Amphille",
        apps: "Sovellukset",
        games: "Pelit",
        donate: "Lahjoita",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Yksi palvelin, synkronoi kaikki",
        servers: "Palvelimet",
        downloadServer: "Lataa palvelin",
        privacyPolicy: "Tietosuojakäytäntö",
        termsAndCondition: "Ehdot",
        github: "Github",
        appStores: "Sovelluskaupat",
        binaries: "Binääritiedostot",
        packageManagers: "Pakettienhallinta",
        allReleases: "Kaikki julkaisun versiot",
        sourceCode: "Lähdekoodi",
        notesAppName: "Muistiinpanot",
        notesAppSubtitle: "Minimalistinen suunnittelu, maksimaalinen toiminnallisuus",
        musicAppName: "Musiikki",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Pilvi",
        photosAppName: "Valokuvat",
        inPlanning: "Suunnittelussa",
        donationPageHeader: "Onko meidän sovellukset ja pelit olleet hauskoja?",
        donationMessage: "Tukesi merkitsee meille paljon ja auttaa meitä tarjoamaan päivityksiä ja parantamaan laatua. Lahjoitustesi avulla voimme käyttää enemmän aikaa kehitykseen, mikä parantaa suoraan sovelluksiemme ja pelien laatua ja täydellisyyttä. Lisäksi lahjoituksesi auttavat kattamaan verkkosivuston hosting- ja sovelluskaupan rekisteröintimaksut. Kiitos paljon tuestasi!",
        latestVersion: "Uusin"
    }
};

export default data;
