import Page from "../../components/Page";
import {DownloadButton} from "../../components/AppDownload";
import React from "react";

export default function NotesAppArchive() {
    return (
        <Page>
            <h1>
                Notes App Archive
            </h1>

            <h2>
                1.1.0 (Latest)
            </h2>
            <p>
                Bug fixes
                <br></br>
                Cleaned up title bar (Desktop)
                <br></br>
                Added indentation and header features
            </p>
            <p>

            <DownloadButton href={"/releases/notes/notes-windows-x64-1.1.0.exe"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>EXE
                </DownloadButton>
                <DownloadButton href={"/releases/notes/notes-windows-x64-1.1.0.zip"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>ZIP
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-macos-1.1.0.dmg"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DMG
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.1.0.tar.zst"} target={"_blank"}
                                rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>TAR
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.1.0.deb"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DEB
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.1.0.rpm"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>RPM
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-arm64-1.1.0.tar.zst"} target={"_blank"}
                                rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>TAR (ARM)
                </DownloadButton>
                <DownloadButton href={"/releases/notes/notes-linux-arm-1.1.0.deb"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DEB (ARM)
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-android-1.1.0.apk"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>APK
                </DownloadButton>

            </p>

            <h2>
                1.0.0
            </h2>

            <p>

                <DownloadButton href={"/releases/notes/notes-windows-x64-1.0.0.exe"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>EXE
                </DownloadButton>
                <DownloadButton href={"/releases/notes/notes-windows-x64-1.0.0.zip"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>ZIP
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-macos-1.0.0.dmg"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DMG
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.0.0.tar.zst"} target={"_blank"}
                                rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>TAR
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.0.0.deb"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DEB
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-x64-1.0.0.rpm"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>RPM
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-linux-arm64-1.0.0.tar.zst"} target={"_blank"}
                                rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>TAR (ARM)
                </DownloadButton>
                <DownloadButton href={"/releases/notes/notes-linux-arm-1.0.0.deb"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>DEB (ARM)
                </DownloadButton>

                <DownloadButton href={"/releases/notes/notes-android-1.0.0.apk"} target={"_blank"} rel="noreferrer">
                    <i className="fa-regular fa-circle-down"></i>APK
                </DownloadButton>

            </p>

        </Page>
    );
}