import React from "react";

const data = {
    translation: {
        appsPageTitle: "Amphi Apps - One Server, Sync All",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Donate for Amphi",
        apps: "Apps",
        games: "Games",
        donate: "Donate",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "One Server, Sync All",
        appsPageSubtitle: "Once you set up a server, you can sync the data of the apps listed below.",
        servers: "Servers",
        downloadServer: "Download Server",
        privacyPolicy: "Privacy",
        termsAndCondition: "Terms",
        github: "Github",
        appStores: "App Stores",
        binaries: "Binaries",
        packageManagers: "Package Managers",
        allReleases: "All Releases",
        sourceCode: "Source Code",
        notesAppName: "Notes",
        notesAppSubtitle: "Minimal design, maximum functionality",
        musicAppName: "Music",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Photos",
        inPlanning: "In Planning",
        latestVersion: "Latest",
    }
};

export default data;