const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Um servidor, sincronize tudo",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Doe para o Amphi",
        apps: "Apps",
        games: "Jogos",
        donate: "Doe",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Um servidor, sincronize tudo",
        servers: "Servidores",
        downloadServer: "Baixar servidor",
        privacyPolicy: "Política de Privacidade",
        termsAndCondition: "Termos e Condições",
        github: "Github",
        appStores: "Lojas de Aplicativos",
        binaries: "Binários",
        packageManagers: "Gerenciadores de Pacotes",
        allReleases: "Todos os Lançamentos",
        sourceCode: "Código Fonte",
        notesAppName: "Notas",
        notesAppSubtitle: "Design minimalista, funcionalidade máxima",
        musicAppName: "Música",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Nuvem",
        photosAppName: "Fotos",
        inPlanning: "Em Planejamento",
        donationPageHeader: "Você gostou dos nossos apps e jogos?",
        donationMessage: "Seu apoio significa muito para nós e nos ajuda a continuar fornecendo atualizações e melhorando a qualidade. Com as suas doações, podemos dedicar mais tempo ao desenvolvimento, o que melhora diretamente a qualidade e a completude de nossos apps e jogos. Além disso, suas contribuições generosas ajudam a cobrir os custos de hospedagem do site e taxas de registro nas lojas de aplicativos. Muito obrigado pelo seu apoio!",
        latestVersion: "Última"
    }
};

export default data;
