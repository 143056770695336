const data = {
    translation: {
        appsPageTitle: "অ্যাম্পি অ্যাপস - এক সার্ভার, সবকিছু সিঙ্ক করুন",
        gamesPageTitle: "অ্যাম্পি গেমস",
        donatePageTitle: "অ্যাম্পির জন্য দান করুন",
        apps: "অ্যাপস",
        games: "গেমস",
        donate: "দান করুন",
        windows: "উইন্ডোজ",
        mac: "ম্যাক",
        linux: "লিনাক্স",
        android: "অ্যান্ড্রয়েড",
        ios: "IOS",
        appsPageHeader: "এক সার্ভার, সবকিছু সিঙ্ক করুন",
        servers: "সার্ভারগুলি",
        downloadServer: "সার্ভার ডাউনলোড করুন",
        privacyPolicy: "গোপনীয়তা",
        termsAndCondition: "শর্তাবলী",
        github: "গিটহাব",
        appStores: "অ্যাপ স্টোরগুলি",
        binaries: "বাইনারি",
        packageManagers: "প্যাকেজ ম্যানেজার",
        allReleases: "সব রিলিজ",
        sourceCode: "সোর্স কোড",
        notesAppName: "নোটস",
        notesAppSubtitle: "সর্বনিম্ন ডিজাইন, সর্বাধিক কার্যকারিতা",
        musicAppName: "মিউজিক",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "ক্লাউড",
        photosAppName: "ফটোস",
        inPlanning: "পরিকল্পনায়",
        donationPageHeader: "আমাদের অ্যাপস এবং গেমস কি আপনি উপভোগ করেছেন?",
        donationMessage: "আপনার সমর্থন আমাদের জন্য অত্যন্ত গুরুত্বপূর্ণ এবং এটি আমাদের আপডেট প্রদান এবং গুণমান উন্নত করতে সাহায্য করে। আপনার দানের মাধ্যমে, আমরা আরও সময় নিবেদন করতে পারি, যা আমাদের অ্যাপস এবং গেমসের পরিপূর্ণতা ও মান উন্নত করে। তাছাড়া, আপনার দান আমাদের ওয়েবসাইট হোস্টিং এবং অ্যাপ স্টোর নিবন্ধন ফি সহায়তা করতে সাহায্য করে। আপনার সহায়তার জন্য অনেক ধন্যবাদ!",
        latestVersion: "সর্বশেষ"
    }
};

export default data;