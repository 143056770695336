const data = {
    translation: {
        appsPageTitle: "Amphi Apps - เซิร์ฟเวอร์เดียว ซิงค์ทุกอย่าง",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "บริจาคให้ Amphi",
        apps: "แอป",
        games: "เกม",
        donate: "บริจาค",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "เซิร์ฟเวอร์เดียว ซิงค์ทุกอย่าง",
        servers: "เซิร์ฟเวอร์",
        downloadServer: "ดาวน์โหลดเซิร์ฟเวอร์",
        privacyPolicy: "นโยบายความเป็นส่วนตัว",
        termsAndCondition: "ข้อกำหนดและเงื่อนไข",
        github: "Github",
        appStores: "ร้านแอป",
        binaries: "ไฟล์ไบนารี",
        packageManagers: "ตัวจัดการแพ็กเกจ",
        allReleases: "ทุกเวอร์ชัน",
        sourceCode: "โค้ดต้นฉบับ",
        notesAppName: "โน้ต",
        notesAppSubtitle: "ดีไซน์มินิมอล ฟังก์ชันสูงสุด",
        musicAppName: "ดนตรี",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "คลาวด์",
        photosAppName: "รูปภาพ",
        inPlanning: "อยู่ในแผนการ",
        donationPageHeader: "คุณชอบแอปและเกมของเราหรือไม่?",
        donationMessage: "การสนับสนุนของคุณมีความหมายมากสำหรับเราและช่วยให้เราสามารถอัปเดตและปรับปรุงคุณภาพได้ ด้วยการบริจาคของคุณเราสามารถใช้เวลามากขึ้นในการพัฒนา ซึ่งจะช่วยเพิ่มคุณภาพและความสมบูรณ์ของแอปและเกมของเรา นอกจากนี้การบริจาคของคุณยังช่วยครอบคลุมค่าใช้จ่ายในการโฮสต์เว็บไซต์และค่าธรรมเนียมการลงทะเบียนร้านแอป ขอบคุณมากสำหรับการสนับสนุนของคุณ!",
        latestVersion: "เวอร์ชันล่าสุด"
    }
};

export default data;
