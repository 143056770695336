const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Jeden server, synchronizujte vše",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Darujte pro Amphi",
        apps: "Aplikace",
        games: "Hry",
        donate: "Darovat",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Jeden server, synchronizujte vše",
        servers: "Servery",
        downloadServer: "Stáhnout server",
        privacyPolicy: "Ochrana soukromí",
        termsAndCondition: "Podmínky",
        github: "Github",
        appStores: "Obchody s aplikacemi",
        binaries: "Binární soubory",
        packageManagers: "Správci balíčků",
        allReleases: "Všechny verze",
        sourceCode: "Zdrojový kód",
        notesAppName: "Poznámky",
        notesAppSubtitle: "Minimalistický design, maximální funkčnost",
        musicAppName: "Hudba",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Fotografie",
        inPlanning: "V plánování",
        donationPageHeader: "Líbily se vám naše aplikace a hry?",
        donationMessage: "Vaše podpora pro nás znamená vše a pomáhá nám pokračovat v poskytování aktualizací a zlepšování kvality. Díky vašim darům můžeme věnovat více času vývoji, což přímo zlepšuje kvalitu a úplnost našich aplikací a her. Vaše štědré příspěvky nám také pomáhají pokrýt náklady na hosting webových stránek a registrační poplatky pro obchody s aplikacemi. Děkujeme vám za vaši podporu!",
        latestVersion: "Nejnovější"
    }
};

export default data;