const data = {
    translation: {
        appsPageTitle: "Amphi Apps - Satu Server, Sinkronkan Semua",
        gamesPageTitle: "Amphi Games",
        donatePageTitle: "Donasi untuk Amphi",
        apps: "Aplikasi",
        games: "Permainan",
        donate: "Donasi",
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
        android: "Android",
        ios: "IOS",
        appsPageHeader: "Satu Server, Sinkronkan Semua",
        servers: "Server",
        downloadServer: "Unduh Server",
        privacyPolicy: "Kebijakan Privasi",
        termsAndCondition: "Syarat dan Ketentuan",
        github: "Github",
        appStores: "Toko Aplikasi",
        binaries: "Biner",
        packageManagers: "Pengelola Paket",
        allReleases: "Semua Rilis",
        sourceCode: "Kode Sumber",
        notesAppName: "Catatan",
        notesAppSubtitle: "Desain minimalis, fungsionalitas maksimal",
        musicAppName: "Musik",
        cubeTimeAppName: "CubeTime",
        cloudAppName: "Cloud",
        photosAppName: "Foto",
        inPlanning: "Dalam Perencanaan",
        donationPageHeader: "Apakah Anda menikmati aplikasi dan permainan kami?",
        donationMessage: "Dukungan Anda sangat berarti bagi kami dan membantu kami terus memberikan pembaruan dan meningkatkan kualitas. Dengan donasi Anda, kami dapat mengalokasikan lebih banyak waktu untuk pengembangan, yang langsung meningkatkan kualitas dan kelengkapan aplikasi dan permainan kami. Selain itu, kontribusi Anda yang dermawan membantu menutupi biaya hosting situs web dan biaya pendaftaran toko aplikasi. Terima kasih banyak atas dukungan Anda!",
        latestVersion: "Terbaru"
    }
};

export default data;
